<template>
    <b-row class="justify-content-center">
        <b-col sm="12" md="12" lg="12" xl="10">

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <div class="d-flex justify-content-between mb-3">
                        <h4 class="mb-0 mt-1">{{ $t('pages.admin.keyManagement.headers.create') }}</h4>
                        <b-button size="sm" type="submit" :disabled="loading" variant="orange">{{ $t("buttons.create") }}</b-button>
                    </div>
                    <hr>
                    <b-row>
                        <b-col sm="12" md="12" lg="12" xl="6">
                            <CCard>
                                <CCardHeader>
                                    {{ $t('pages.admin.keyManagement.headers.settings') }}
                                </CCardHeader>
                                <CCardBody>
                                    <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                                        <validation-provider
                                            :name="$t('pages.admin.keyManagement.form.name')"
                                            :rules="{ required: true, min: 3, max: 64 }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                                :label="$t('pages.admin.keyManagement.form.name')"
                                            >
                                                <b-form-input
                                                    v-model="form.name"
                                                    placeholder="z.B. RuB2 Praktikum 01"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="name-feedback"
                                                    maxlength="64"
                                                ></b-form-input>
                                                <b-form-invalid-feedback id="name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider
                                            :name="$t('pages.admin.keyManagement.form.expire')"
                                            :rules="{ required: true }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                                :label="$t('pages.admin.keyManagement.form.expire')"
                                            >
                                                <b-form-datepicker
                                                    v-model="form.expireDate"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="expire-feedback"
                                                    hide-header
                                                    v-bind="$t('modules.datepicker') || {}"
                                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                    :min="new Date()"
                                                ></b-form-datepicker>
                                                <b-form-invalid-feedback id="expire-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                            </b-form-group>
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6">
                                                <b-form-timepicker
                                                    v-model="form.expireTime"
                                                    locale="de"
                                                    no-close-button
                                                    hide-header
                                                    aria-describedby="expire-feedback"
                                                    :state="getValidationState(validationContext)"
                                                    v-bind="$t('modules.timepicker') || {}"
                                                ></b-form-timepicker>
                                                <b-form-invalid-feedback id="expire-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider
                                            :name="$t('pages.admin.keyManagement.form.maxUses.label')"
                                            :rules="{ required: true, min_value: -1, max_value: 99999 }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                                :label="$t('pages.admin.keyManagement.form.maxUses.label')"
                                            >
                                                <b-input-group>
                                                    <b-form-input
                                                        type="number"
                                                        min="-1"
                                                        max="99999"
                                                        v-model="form.maxUses"
                                                        :state="getValidationState(validationContext)"
                                                        aria-describedby="maxUses-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-input-group-append>
                                                        <b-input-group-text>{{ $t('pages.admin.keyManagement.form.maxUses.times') }}</b-input-group-text>
                                                    </b-input-group-append>
                                                    <b-form-invalid-feedback id="maxUses-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>

                                            </b-form-group>
                                        </validation-provider>
                                        <hr>
                                        <validation-provider
                                            :name="$t('pages.admin.keyManagement.form.expireResourceType')"
                                            :rules="{ required: true }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                                :label="$t('pages.admin.keyManagement.form.expireResourceType')"
                                            >
                                                <b-form-select
                                                    v-model="form.expireResourceType"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="expireResourceType-feedback"
                                                >
                                                    <!--<b-form-select-option value="never">{{ $t('pages.admin.keyManagement.values.expireResourceType.never') }}</b-form-select-option>-->
                                                    <b-form-select-option value="date">{{ $t('pages.admin.keyManagement.values.expireResourceType.date', {date: form.expireResourceDate ? $moment(form.expireResourceDate).format('DD.MM.YYYY') : 'DD.MM.YYYY'}) }}</b-form-select-option>
                                                    <b-form-select-option value="days">{{ $t('pages.admin.keyManagement.values.expireResourceType.days', {days: form.expireResourceDays}) }}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider
                                            v-show="form.expireResourceType === 'days'"
                                            :name="$t('pages.admin.keyManagement.form.expireResource.days')"
                                            :rules="{ required: true }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                                :label="$t('pages.admin.keyManagement.form.expireResource.days')"
                                            >
                                                <b-input-group>
                                                    <b-form-input
                                                        v-model="form.expireResourceDays"
                                                        type="number"
                                                        :state="getValidationState(validationContext)"
                                                        aria-describedby="expireResourceType-feedback"
                                                    />
                                                    <b-input-group-append>
                                                        <b-input-group-text>{{ $t('pages.admin.keyManagement.form.days') }}</b-input-group-text>
                                                    </b-input-group-append>
                                                    <b-form-invalid-feedback id="expireResourceType-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider
                                            v-show="form.expireResourceType === 'date'"
                                            :name="$t('pages.admin.keyManagement.form.expireResource.date')"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                                :label="$t('pages.admin.keyManagement.form.expireResource.date')"
                                            >
                                                <b-input-group>
                                                    <b-form-datepicker
                                                        v-model="form.expireResourceDate"
                                                        :state="expireResourceDateValid"
                                                        aria-describedby="expireResourceDate-feedback"
                                                        hide-header
                                                        v-bind="$t('modules.datepicker') || {}"
                                                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                        :min="new Date()"
                                                    ></b-form-datepicker>
                                                    <b-form-invalid-feedback id="expireResourceDate-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider
                                            v-show="form.expireResourceType === 'date'"
                                            :name="$t('pages.admin.keyManagement.form.expireResource.date')"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-cols-md="6"
                                            >
                                                <b-input-group>
                                                    <b-form-timepicker
                                                        v-model="form.expireResourceTime"
                                                        locale="de"
                                                        no-close-button
                                                        hide-header
                                                        aria-describedby="expireResourceTime-feedback"
                                                        :state="getValidationState(validationContext)"
                                                        v-bind="$t('modules.timepicker') || {}"
                                                    ></b-form-timepicker>
                                                    <b-form-invalid-feedback id="expireResourceTime-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>

                                    </b-overlay>
                                </CCardBody>

                            </CCard>
                            <CCard>
                                <CCardHeader>
                                    {{ $t('pages.admin.keyManagement.headers.addResources') }}
                                </CCardHeader>
                                <CCardBody>
                                    <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="5"
                                            :label="$t('pages.admin.keyManagement.form.resourceType')"
                                        >
                                            <b-form-select
                                                v-model="resourceForm.resourceType"
                                                :options="resourceTypes"
                                                @change="onLoadResources"
                                                @keypress.enter.stop
                                            ></b-form-select>
                                        </b-form-group>
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="5"
                                            :label="$t('pages.admin.keyManagement.form.resource')"
                                            class="mb-0"
                                        >
                                            <v-select
                                                :options="resources"
                                                v-model="resourceForm.resource"
                                                :get-option-label="(option) => option.name"
                                                @keypress.enter.stop
                                            ></v-select>
                                        </b-form-group>
                                    </b-overlay>
                                </CCardBody>
                                <CCardFooter class="d-flex justify-content-end">
                                    <b-button type="button" size="sm" variant="orange" @click="addResource">
                                        <font-awesome-icon icon="plus"/>
                                        {{ $t("buttons.add") }}
                                    </b-button>
                                </CCardFooter>
                            </CCard>
                        </b-col>
                        <b-col sm="12" md="12" lg="12" xl="6">
                            <CCard>
                                <CCardHeader>
                                    {{ $t('pages.admin.keyManagement.headers.resources') }}
                                </CCardHeader>
                                <CCardBody>
                                    <b-table
                                        ref="resource-table"
                                        :items="resourceItems"
                                        :fields="resourceFields"
                                        small
                                        striped
                                        show-empty
                                        :empty-text="$t('generally.tables.emptyText')"
                                        :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                                    >
                                        <template #cell(resource)="row">
                                            {{ row.item.resource.name }}
                                        </template>
                                        <template #cell(action)="row">
                                            <b-link @click="removeResource(row.item)">
                                                <font-awesome-icon icon="trash-alt" class="text-danger"/>
                                            </b-link>
                                        </template>
                                    </b-table>
                                </CCardBody>
                            </CCard>
                        </b-col>
                    </b-row>
                    <hr>
                    <div class="d-flex justify-content-end mb-3">
                        <b-button type="submit" :disabled="loading" variant="orange">{{ $t("buttons.create") }}</b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-col>
    </b-row>
</template>

<script>
import keyService from "../../../services/keyService";
import mainServices from "../../../services/mainServices";

export default {
    name: "NewKey",
    data: () => ({
        resourceTypes: [],
        resources: [],
        resourceItems: [],
        resourceFields: [],
        resourceForm: {
            resource: '',
            resourceType: 'pool'
        },
        expireResourceDateValid: null,
        form: {
            name: '',
            resources: [],
            expireDate: '',
            expireTime: '23:59',
            expireResourceType: 'date',
            expireResourceDate: '',
            expireResourceTime: '23:59',
            expireResourceDays: '7',
            maxUses: '1'
        },
        loading: false,
        locale: 'de'
    }),
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.resourceFields = [
            {key: 'resourceType', label: this.$t('pages.admin.keyManagement.table.resourceType')},
            {key: 'resource', label: this.$t('pages.admin.keyManagement.table.resource')},
            {key: 'action', label: ''}
        ];
        this.locale = mainServices.getLocale();
        keyService.getResourceTypes().then(response => {
            this.resourceTypes = response.data;
            this.onLoadResources();
        });
    },
    methods: {
        addResource() {
            if (this.resourceForm.resource === '' || this.resourceForm.resourceType === '') {
                this.$toastr.e(this.$t('pages.admin.keyManagement.messages.error.noResourceSelected'));
                return;
            }
            if (this.resourceItems.some(item => item.resource === this.resourceForm.resource && item.resourceType === this.resourceForm.resourceType)) {
                this.$toastr.e(this.$t('pages.admin.keyManagement.messages.error.resourceAlreadyAdded'));
                return;
            }
            this.resourceItems.push(Object.assign({}, this.resourceForm));
            this.resourceForm.resource = '';
        },
        removeResource(deleteItem) {
            if (this.resourceItems.some(item => item.resource === deleteItem.resource && item.resourceType === deleteItem.resourceType)) {
                this.resourceItems.splice(this.resourceItems.findIndex(item => item.resource === deleteItem.resource && item.resourceType === deleteItem.resourceType), 1);
            } else
                this.$toastr.e(this.$t('pages.admin.keyManagement.messages.error.resourceNotFound'));
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        onLoadResources() {
            keyService.getResources(this.resourceForm.resourceType).then(response => {
                response.data = response.data.map(item => {
                    item.name = mainServices.parseJSON(item.name);
                    if(typeof item.name === "object"){
                        item.name = item.name[this.locale]
                    }
                    return item;
                });
                this.resources = response.data;
                this.resourceForm.resource = '';
            }).catch(error => {
                this.resources = [];
                this.resourceForm.resource = '';
            });
        },
        submit() {
            if (this.resourceItems.length <= 0) {
                this.$toastr.e(this.$t('pages.admin.keyManagement.messages.error.minResources'));
                return;
            }
            if (this.form.expireResourceType === 'date' && this.form.expireResourceDate === '') {
                this.$toastr.e(this.$t('pages.admin.keyManagement.messages.error.noDateSelected'));
                this.expireResourceDateValid = false;
                return;
            }
            if (!this.loading) {
                let data = {};
                data.name = this.form.name;
                data.expiresAt = this.form.expireDate + ' ' + this.form.expireTime;
                data.maxUses = parseInt(this.form.maxUses);
                data.expireResourceType = this.form.expireResourceType;
                data.expireResourceDate = data.expireResourceType === 'date' ? this.$moment(this.form.expireResourceDate).format('YYYY-MM-DD') + ' ' + this.form.expireResourceTime : undefined;
                data.expireResourceDays = data.expireResourceType === 'days' ? parseInt(this.form.expireResourceDays) : undefined;
                data.resourceIds = this.resourceItems.map(item => ({
                    id: item.resource.id,
                    type: item.resourceType
                }));
                this.loading = true;
                keyService.add(data).then(() => {
                    this.$toastr.s(this.$t('pages.admin.keyManagement.messages.success.created'));
                    this.$router.push({name: 'AdminKeyManagementList'});
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error);
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
